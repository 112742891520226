import { ReactNode } from "react";
import { ArrowRight as ArrowRightIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Link } from "@/navigation";
import Image from "next/image";

const BentoGrid = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "grid w-full auto-rows-[22rem] grid-cols-3 gap-4",
        className,
      )}
    >
      {children}
    </div>
  );
};

const BentoCard = ({
  name,
  className,
  background,
  Icon,
  IconImg,
  description,
  href,
  cta,
}: {
  name: string;
  className: string;
  background: ReactNode;
  Icon?: any;
  IconImg?: string;
  description: string;
  href?: string;
  cta?: string;
}) => (
  <div
    key={name}
    className={cn(
      "group relative col-span-3 flex flex-col justify-between overflow-hidden rounded-3xl bg-neutral-100",
      className,
    )}
  >
    <div>{background}</div>
    <div className={`z-10 flex transform-gpu flex-col gap-1 p-6 transition-all duration-300 ${href && cta ? `group-hover:-translate-y-14` : ''}`}>

      {IconImg && (
        <div
          className={`h-12 w-12 origin-left transform-gpu transition-all duration-300 ease-in-out ${href && cta ? `group-hover:scale-75` : ''}`}
        >
          <Image
            src={IconImg}
            alt={name}
            fill
          />
        </div>
      )}
      {Icon && (
        <Icon className={`h-12 w-12 origin-left transform-gpu text-brand-blue-mid transition-all duration-300 ease-in-out ${href && cta ? `group-hover:scale-75` : ''}`} />
      )}
      <h3 className="text-lg md:text-xl font-bold text-brand-blue-mid">
        {href ? (
          <Link href={href} className="font-bold">{name}</Link>
        ) : (
          <>{name}</>
        )}

      </h3>
      <p className="max-w-lg text-brand-blue-mid">{description}</p>
    </div>

    {href && cta && (
      <div
        className={cn(
          "absolute bottom-0 flex w-full translate-y-10 transform-gpu flex-row items-center p-6 opacity-0 transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100",
        )}
      >
        <Link href={href} className="flex items-center text-brand-blue-mid font-bold">
          {cta}
          <ArrowRightIcon className="ml-2 h-6 w-6" />
        </Link>
      </div>
    )}
    <div className="pointer-events-none absolute inset-0 transform-gpu transition-all duration-300 group-hover:bg-black/[.03] group-hover:dark:bg-neutral-800/10" />
  </div>
);

export { BentoCard, BentoGrid };
