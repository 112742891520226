"use client"
import React, { useEffect, useRef } from "react";
import { animate, useInView } from "framer-motion";

export const BrandStats = () => {
  return (
    <div className="bg-brand-blue-dark mx-auto w-full">
      <div className="mx-auto colmena-p py-12 md:py-24">
        <h2 className="mb-8 text-center text-white">
          Trayectoria que Respalda Resultados
        </h2>

        <div className="flex flex-row items-center justify-center gap-x-1">
          <Stat
            num={500}
            prefix="+"
            subheading="Proyectos completados en los ultimos 5 años"
          />
          <div className="bg-brand-blue-shade-2 h-12 w-[1px]" />
          <Stat
            num={97}
            decimals={1}
            suffix="%"
            subheading="Clientes satisfechos con nuestro trabajo profesional"
          />
          <div className="bg-brand-blue-shade-2 h-12 w-[1px]" />
          <Stat
            num={10}
            suffix=" años"
            subheading="Experiencia en Soluciones Web a medida"
          />
        </div>
      </div>
    </div>
  );
};

const Stat = ({
  num,
  prefix,
  suffix,
  decimals = 0,
  subheading,
}: {
  num: number;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  subheading: string;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (!isInView) return;

    animate(0, num, {
      duration: 2.5,
      onUpdate(value) {
        if (!ref.current) return;
        ref.current.textContent = value.toFixed(decimals);
      },
    });
  }, [num, decimals, isInView]);

  return (
    <div className="flex w-72 flex-col items-center py-8 sm:py-0 px-2 items-start">
      <p className="mb-2 text-center text-3xl md:text-4xl lg:text-5xl font-semibold sm:text-6xl text-white">
        {prefix && <span className="md:text-5xl">{prefix}</span>}
        <span ref={ref}></span>
        {suffix && <span className="md:text-5xl">{suffix}</span>}
      </p>
      <p className="max-w-48 text-sm md:text-base text-center text-white">{subheading}</p>
    </div >
  );
};