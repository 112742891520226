import { twMerge } from "tailwind-merge";
import { Link } from '@/navigation';

const GhostButton = ({ href, children, className, ...rest }: { href: string, children: any, className?: string }) => {
  return (
    <Link
      href={href}
      className={
        twMerge(
          "rounded-2xl px-4 py-2 text-white/80 bg-transparent transition-all hover:scale-[1.02] hover:bg-brand-blue-dark hover:text-white active:scale-[0.98]",
          className
        )
      }
      {...rest}
    >
      {children}
    </Link>
  );
};
export default GhostButton;