"use client";
import React from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";

import { AnimatedBeamTech } from "@/components/ui/AnimatedBeam";
import { AnimatedList } from "@/components/ui/AnimatedList";
import { BentoCard, BentoGrid } from "@/components/ui/BentoGrid";
import { Marquee } from "@/components/ui/Marquee";

import PhoneNotification from "./ui/PhoneNotification";
import VideoComponent from "./ui/VideoComponent";

import Lottie from "lottie-react";
import privacyGraphic from '@/components/animations/privacy.json';
import passwordGraphic from '@/components/animations/password.json';

const industries = [
  {
    name: "Servicios Profesionales",
    icon: '/icons/professionals.svg',
    list: [
      "Consultorías",
      "Abogados",
      "Contadores",
      "Asesores",
    ]
  },
  {
    name: "Real Estate",
    icon: '/icons/real-estate.svg',
    list: [
      "Agencias",
      "Brokers",
      "Desarrolladoras",
      "Property Management",
    ]
  },
  {
    name: "Salud y Bienestar",
    icon: '/icons/healthcare.svg',
    list: [
      "Clínicas",
      "Hospitales",
      "Farmacias",
      "Laboratorios",
      "Consultorios",
      "Wellness Centers",
      "Spas",
    ]
  },
  {
    name: "Retail y E-commerce",
    icon: '/icons/retail.svg',
    list: [
      "Tiendas",
      "Boutiques",
      "E-commerce",
      "Marketplaces",
      "Retail Digital",
    ]
  },
  {
    name: "Tecnología",
    icon: '/icons/startups.svg',
    list: [
      "Startups",
      "SaaS",
      "Fintech",
      "Apps",
    ]
  },
  {
    name: "Servicios Locales",
    icon: '/icons/restaurant.svg',
    list: [
      "Restaurantes",
      "Eventos",
      "Servicios Domésticos",
      "Entretenimiento",
    ]
  },
];

let notifications = [
  {
    name: "Primera Posición Alcanzada",
    description: " \"diseño web miami\" ahora en #1 de Google",
    time: "15m ago",
    icon: "📈",
    color: "#00C9A7",
  },
  {
    name: "Keyword Dominada",
    description: "Top 3 para \"agencia web florida\"",
    time: "10m ago",
    icon: "🎯",
    color: "#FFB800",
  },
  {
    name: "Mejora de Posición",
    description: "+15 posiciones para keyword principal",
    time: "5m ago",
    icon: "📊",
    color: "#FF3D71",
  },
  {
    name: "Nueva Keyword Rankeada",
    description: "Top 10 para \"desarrollo web empresarial\"",
    time: "4m ago",
    icon: "🔍",
    color: "#1E86FF",
  },
  {
    name: "Tráfico Orgánico",
    description: "+150% incremento en visitas orgánicas",
    time: "3m ago",
    icon: "🗞️",
    color: "#1E86FF",
  },
  {
    name: "Engagement",
    description: "-35% reducción en tasa de rebote",
    time: "a moment ago",
    icon: "⚡",
    color: "#1E86FF",
  },
  {
    name: "Conversiones",
    description: "+65% incremento en leads orgánicos",
    time: "now",
    icon: "🎯",
    color: "#1E86FF",
  },
];

notifications = Array.from({ length: 10 }, () => notifications).flat();

const features = [
  {
    IconImg: "/icons/experts.svg",
    name: "Equipo Experto",
    description: "De ideas a resultados digitales. Nuestro equipo multidisciplinario ha transformado +200 negocios combinando diseño estratégico con tecnología de vanguardia. No solo construimos websites, creamos experiencias que convierten.",
    href: "/about",
    cta: "Conoce sobre Colmena",
    className: "col-span-3 md:col-span-2",
    background: (
      <Marquee
        pauseOnHover
        className="absolute p-0 top-4 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_20%,#000_100%)] "
      >
        {industries.map((item, idx) => (
          <figure
            key={idx}
            className={cn(
              "relative w-44 cursor-pointer overflow-hidden rounded-xl border p-4",
              "border bg-white hover:border-brand-blue-reg",
              "transform-gpu blur-[1px] transition-all duration-300 ease-out hover:blur-none",
            )}
          >
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-col gap-2">
                <div className="w-12 h-12 relative">
                  <Image src={item.icon} alt={item.name} fill className="object-contain" />
                </div>
                <figcaption className="text-sm font-bold text-brand-blue-mid">
                  {item.name}
                </figcaption>
              </div>
            </div>
            {item.list &&
              <ul className="mt-2 text-xs text-brand-blue-mid">
                {item.list.map((subitem, idx) => (
                  <li key={idx}>{subitem}</li>
                ))}
              </ul>
            }
          </figure>
        ))}
      </Marquee>
    ),
  },
  {
    IconImg: "/icons/strategy.svg",
    name: "SEO Estratégico",
    description: "Potencia tu Autoridad digital con estrategias que van más allá de keywords, creamos contenido que conecta con tu audiencia y te posiciona como referente en tu industria.",
    href: "/websites",
    cta: "Descubre Cómo",
    className: "col-span-3 md:col-span-1",
    background: (
      <AnimatedList
        className="w-full absolute px-6 top-4 -right-14 h-[300px] w-full border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_45%,#000_100%)] group-hover:scale-105"
      >
        {notifications.map((item, idx) => (
          <PhoneNotification {...item} key={idx} />
        ))}
      </AnimatedList>
    ),
  },

  {
    IconImg: "/icons/speed.svg",
    name: "Velocidad Comprobada",
    description: "Alcanzamos 90+ en PageSpeed y tiempos de carga menores a 2 segundos. La diferencia entre perder visitantes o convertirlos en clientes.",
    className: "col-span-3 md:col-span-1",
    background: (
      <div
        className="w-[90%] rounded-xl overflow-hidden absolute -right-4 top-4 origin-top rounded-md border transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105"
      >
        <VideoComponent webmSrc="/videos/pagespeed.webp" mp4Src="/videos/pagespeed.mp4" className="object-cover rounded-xl relative overflow-hidden" />
      </div>
    ),
  },
  {
    IconImg: "/icons/tech.svg",
    name: "Tecnología con Propósito",
    description: "Cada tecnología responde a una estrategia clara. Combinamos las mejores herramientas y prácticas para crear soluciones digitales que perduran",
    className: "col-span-3 md:col-span-1",
    href: "/about",
    cta: "Explora Nuestro Enfoque",
    background: (
      <AnimatedBeamTech className="absolute -right-2 top-4 w-2/3 h-[300px] transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_30%,#000_100%)] group-hover:scale-105" />
    ),
  },
  {
    IconImg: "/icons/solutions.svg",
    name: "Soluciones Confiables",
    description: "Desarrollamos soluciones robustas y te brindamos el respaldo necesario para mantener tu presencia digital impecable.",
    className: "col-span-3 md:col-span-1",
    background: (
      <div
        className="absolute right-0 top-4 origin-top rounded-xl bg-white border transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105"
      >
        <Lottie animationData={privacyGraphic} loop={true} className="object-contain scale-125 h-64 w-64" />
      </div>
    ),
  },
];

export default function BentoBox() {
  return (
    <>
      <div className='bg-brand-blue-dark w-full relative colmena-p pb-12'>
        <div className="max-w-screen-xl mx-auto flex flex-col gap-y-12">

          <h2 className="colmena-title-h2 mx-auto text-center w-full text-brand-yellow">Escala tu Visión de Negocio</h2>

          <BentoGrid>
            {features.map((feature, idx) => (
              <BentoCard key={idx} {...feature} />
            ))}
          </BentoGrid>
        </div>
      </div>
    </>
  );
}

